.ReactTable {
  border: 1px solid #0000001a;
  flex-direction: column;
  display: flex;
  position: relative;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  width: 100%;
  border-collapse: collapse;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-thead {
  user-select: none;
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
}

.ReactTable .rt-thead.-headerGroups {
  background: #00000008;
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px #00000026;
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: 1px solid #0000000d;
  padding: 5px;
  line-height: normal;
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  position: relative;
  box-shadow: inset 0 0 #0000;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px #0009;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px #0009;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

.ReactTable .rt-thead .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-thead .rt-th:focus {
  outline: none;
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}

.ReactTable .rt-thead .rt-header-pivot:after, .ReactTable .rt-thead .rt-header-pivot:before {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: solid #0000;
  position: absolute;
  top: 50%;
  left: 100%;
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-width: 8px;
  border-color: #fff0 #fff0 #fff0 #fff;
  margin-top: -8px;
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-width: 10px;
  border-color: #6660 #6660 #6660 #f7f7f7;
  margin-top: -10px;
}

.ReactTable .rt-tbody {
  flex-direction: column;
  flex: 99999 auto;
  display: flex;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #0000000d;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #00000005;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}

.ReactTable .rt-tr-group {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: stretch;
  display: flex;
}

.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
}

.ReactTable .rt-th, .ReactTable .rt-td {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0;
  padding: 7px 5px;
  transition: width .3s, min-width .3s, padding .3s, opacity .3s;
  overflow: hidden;
}

.ReactTable .rt-th.-hidden, .ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  opacity: 0 !important;
  border: 0 !important;
  padding: 0 !important;
}

.ReactTable .rt-expander {
  color: #0000;
  margin: 0 10px;
  display: inline-block;
  position: relative;
}

.ReactTable .rt-expander:after {
  content: "";
  width: 0;
  height: 0;
  cursor: pointer;
  border-top: 7px solid #000c;
  border-left: 5.04px solid #0000;
  border-right: 5.04px solid #0000;
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-90deg);
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}

.ReactTable .rt-resizer {
  width: 36px;
  cursor: col-resize;
  z-index: 10;
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -18px;
}

.ReactTable .rt-tfoot {
  flex-direction: column;
  flex: 1 0 auto;
  display: flex;
  box-shadow: 0 0 15px #00000026;
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid #0000000d;
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}

.ReactTable.-striped .rt-tr.-odd {
  background: #00000008;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #0000000d;
}

.ReactTable .-pagination {
  z-index: 1;
  border-top: 2px solid #0000001a;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 3px;
  display: flex;
  box-shadow: 0 0 15px #0000001a;
}

.ReactTable .-pagination input, .ReactTable .-pagination select {
  font-size: inherit;
  background: #fff;
  border: 1px solid #0000001a;
  border-radius: 3px;
  outline: none;
  padding: 5px 7px;
  font-weight: normal;
}

.ReactTable .-pagination .-btn {
  appearance: none;
  width: 100%;
  height: 100%;
  color: #0009;
  cursor: pointer;
  background: #0000001a;
  border: 0;
  border-radius: 3px;
  outline: none;
  padding: 6px;
  font-size: 1em;
  transition: all .1s;
  display: block;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  color: #fff;
  background: #0000004d;
}

.ReactTable .-pagination .-previous, .ReactTable .-pagination .-next {
  text-align: center;
  flex: 1;
}

.ReactTable .-pagination .-center {
  text-align: center;
  flex-flow: wrap;
  flex: 1.5;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.ReactTable .-pagination .-pageInfo {
  white-space: nowrap;
  margin: 3px 10px;
  display: inline-block;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center;
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}

.ReactTable .rt-noData {
  z-index: 1;
  pointer-events: none;
  color: #00000080;
  background: #fffc;
  padding: 20px;
  transition: all .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactTable .-loading {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  background: #fffc;
  transition: all .3s;
  display: block;
  position: absolute;
  inset: 0;
}

.ReactTable .-loading > div {
  text-align: center;
  width: 100%;
  color: #0009;
  font-size: 15px;
  transition: all .3s cubic-bezier(.25, .46, .45, .94);
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-52%);
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active > div {
  transform: translateY(50%);
}

.ReactTable .rt-resizing .rt-th, .ReactTable .rt-resizing .rt-td {
  cursor: col-resize;
  user-select: none;
  transition: none !important;
}

/*# sourceMappingURL=index.975a6ec0.css.map */
